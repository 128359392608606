/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const HandlerErrorCode = {
    errorCodeUnknown: 'UNKNOWN',
    errorCodeNotFound: 'NOT_FOUND',
    errorCodeValidationFailed: 'VALIDATION_FAILED',
    errorCodeNoScoreGroupSelected: 'NO_SCORE_GROUP_SELECTED',
    errorCodeAccessTimeRestricted: 'TIME_RESTRICTED',
    errorCodeMaximumNumberChallengeInstancesReached: 'MAXIMUM_NUMBER_CHALLENGE_INSTANCES_REACHED',
    errorCodeCannotProlongChallengeInstance: 'CANNOT_PROLONG_CHALLENGE_INSTANCE'
} as const;
export type HandlerErrorCode = typeof HandlerErrorCode[keyof typeof HandlerErrorCode];


export function instanceOfHandlerErrorCode(value: any): boolean {
    for (const key in HandlerErrorCode) {
        if (Object.prototype.hasOwnProperty.call(HandlerErrorCode, key)) {
            if (HandlerErrorCode[key as keyof typeof HandlerErrorCode] === value) {
                return true;
            }
        }
    }
    return false;
}

export function HandlerErrorCodeFromJSON(json: any): HandlerErrorCode {
    return HandlerErrorCodeFromJSONTyped(json, false);
}

export function HandlerErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerErrorCode {
    return json as HandlerErrorCode;
}

export function HandlerErrorCodeToJSON(value?: HandlerErrorCode | null): any {
    return value as any;
}

export function HandlerErrorCodeToJSONTyped(value: any, ignoreDiscriminator: boolean): HandlerErrorCode {
    return value as HandlerErrorCode;
}

