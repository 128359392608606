/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ChallengeDifficulty = {
    DifficultyEasy: 1,
    DifficultyMedium: 2,
    DifficultyHard: 3
} as const;
export type ChallengeDifficulty = typeof ChallengeDifficulty[keyof typeof ChallengeDifficulty];


export function instanceOfChallengeDifficulty(value: any): boolean {
    for (const key in ChallengeDifficulty) {
        if (Object.prototype.hasOwnProperty.call(ChallengeDifficulty, key)) {
            if (ChallengeDifficulty[key as keyof typeof ChallengeDifficulty] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ChallengeDifficultyFromJSON(json: any): ChallengeDifficulty {
    return ChallengeDifficultyFromJSONTyped(json, false);
}

export function ChallengeDifficultyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengeDifficulty {
    return json as ChallengeDifficulty;
}

export function ChallengeDifficultyToJSON(value?: ChallengeDifficulty | null): any {
    return value as any;
}

export function ChallengeDifficultyToJSONTyped(value: any, ignoreDiscriminator: boolean): ChallengeDifficulty {
    return value as ChallengeDifficulty;
}

