/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlerHealthResponseDetail } from './HandlerHealthResponseDetail';
import {
    HandlerHealthResponseDetailFromJSON,
    HandlerHealthResponseDetailFromJSONTyped,
    HandlerHealthResponseDetailToJSON,
    HandlerHealthResponseDetailToJSONTyped,
} from './HandlerHealthResponseDetail';

/**
 * The health response.
 * @export
 * @interface HandlerHealthResponse
 */
export interface HandlerHealthResponse {
    /**
     * Additional details regarding the health status.
     * @type {HandlerHealthResponseDetail}
     * @memberof HandlerHealthResponse
     */
    detail: HandlerHealthResponseDetail;
    /**
     * General status of the API:
     * * operational - The API is functional.
     * * degraded - The API is not available.
     * @type {string}
     * @memberof HandlerHealthResponse
     */
    message: HandlerHealthResponseMessageEnum;
}


/**
 * @export
 */
export const HandlerHealthResponseMessageEnum = {
    Operational: 'operational',
    Degraded: 'degraded'
} as const;
export type HandlerHealthResponseMessageEnum = typeof HandlerHealthResponseMessageEnum[keyof typeof HandlerHealthResponseMessageEnum];


/**
 * Check if a given object implements the HandlerHealthResponse interface.
 */
export function instanceOfHandlerHealthResponse(value: object): value is HandlerHealthResponse {
    if (!('detail' in value) || value['detail'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function HandlerHealthResponseFromJSON(json: any): HandlerHealthResponse {
    return HandlerHealthResponseFromJSONTyped(json, false);
}

export function HandlerHealthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerHealthResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'detail': HandlerHealthResponseDetailFromJSON(json['detail']),
        'message': json['message'],
    };
}

  export function HandlerHealthResponseToJSON(json: any): HandlerHealthResponse {
      return HandlerHealthResponseToJSONTyped(json, false);
  }

  export function HandlerHealthResponseToJSONTyped(value?: HandlerHealthResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'detail': HandlerHealthResponseDetailToJSON(value['detail']),
        'message': value['message'],
    };
}

