/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HandlerScoreboardEntryDto
 */
export interface HandlerScoreboardEntryDto {
    /**
     * The number of solved flags.
     * @type {number}
     * @memberof HandlerScoreboardEntryDto
     */
    flags: number;
    /**
     * The ID of the participant.
     * @type {string}
     * @memberof HandlerScoreboardEntryDto
     */
    participantId: string;
    /**
     * The Name of the participant.
     * @type {string}
     * @memberof HandlerScoreboardEntryDto
     */
    participantName: string;
    /**
     * The participant's points.
     * @type {number}
     * @memberof HandlerScoreboardEntryDto
     */
    points: number;
    /**
     * The participant's rank.
     * @type {number}
     * @memberof HandlerScoreboardEntryDto
     */
    rank: number;
}

/**
 * Check if a given object implements the HandlerScoreboardEntryDto interface.
 */
export function instanceOfHandlerScoreboardEntryDto(value: object): value is HandlerScoreboardEntryDto {
    if (!('flags' in value) || value['flags'] === undefined) return false;
    if (!('participantId' in value) || value['participantId'] === undefined) return false;
    if (!('participantName' in value) || value['participantName'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    return true;
}

export function HandlerScoreboardEntryDtoFromJSON(json: any): HandlerScoreboardEntryDto {
    return HandlerScoreboardEntryDtoFromJSONTyped(json, false);
}

export function HandlerScoreboardEntryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerScoreboardEntryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'flags': json['flags'],
        'participantId': json['participantId'],
        'participantName': json['participantName'],
        'points': json['points'],
        'rank': json['rank'],
    };
}

  export function HandlerScoreboardEntryDtoToJSON(json: any): HandlerScoreboardEntryDto {
      return HandlerScoreboardEntryDtoToJSONTyped(json, false);
  }

  export function HandlerScoreboardEntryDtoToJSONTyped(value?: HandlerScoreboardEntryDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'flags': value['flags'],
        'participantId': value['participantId'],
        'participantName': value['participantName'],
        'points': value['points'],
        'rank': value['rank'],
    };
}

