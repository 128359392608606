/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChallengeDifficulty } from './ChallengeDifficulty';
import {
    ChallengeDifficultyFromJSON,
    ChallengeDifficultyFromJSONTyped,
    ChallengeDifficultyToJSON,
    ChallengeDifficultyToJSONTyped,
} from './ChallengeDifficulty';

/**
 * The response for a single challenge.
 * @export
 * @interface HandlerSingleChallengeResponse
 */
export interface HandlerSingleChallengeResponse {
    /**
     * The unique slug of the category.
     * @type {string}
     * @memberof HandlerSingleChallengeResponse
     */
    categorySlug: string;
    /**
     * The description of the challenge.
     * @type {string}
     * @memberof HandlerSingleChallengeResponse
     */
    description: string;
    /**
     * The difficulty of the challenge.
     * @type {ChallengeDifficulty}
     * @memberof HandlerSingleChallengeResponse
     */
    difficulty: ChallengeDifficulty;
    /**
     * The name of the challenge.
     * @type {string}
     * @memberof HandlerSingleChallengeResponse
     */
    name: string;
    /**
     * The order of the challenge within the category.
     * @type {number}
     * @memberof HandlerSingleChallengeResponse
     */
    order: number;
    /**
     * The slug of the challenge. It is unique for its category.
     * @type {string}
     * @memberof HandlerSingleChallengeResponse
     */
    slug: string;
    /**
     * The full URL to the archive of static files associated with this challenge.
     * `null` if there are no static files associated to this challenge.
     * @type {string}
     * @memberof HandlerSingleChallengeResponse
     */
    staticFilesArchiveUrl?: string;
}



/**
 * Check if a given object implements the HandlerSingleChallengeResponse interface.
 */
export function instanceOfHandlerSingleChallengeResponse(value: object): value is HandlerSingleChallengeResponse {
    if (!('categorySlug' in value) || value['categorySlug'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('difficulty' in value) || value['difficulty'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('order' in value) || value['order'] === undefined) return false;
    if (!('slug' in value) || value['slug'] === undefined) return false;
    return true;
}

export function HandlerSingleChallengeResponseFromJSON(json: any): HandlerSingleChallengeResponse {
    return HandlerSingleChallengeResponseFromJSONTyped(json, false);
}

export function HandlerSingleChallengeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerSingleChallengeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'categorySlug': json['categorySlug'],
        'description': json['description'],
        'difficulty': ChallengeDifficultyFromJSON(json['difficulty']),
        'name': json['name'],
        'order': json['order'],
        'slug': json['slug'],
        'staticFilesArchiveUrl': json['staticFilesArchiveUrl'] == null ? undefined : json['staticFilesArchiveUrl'],
    };
}

  export function HandlerSingleChallengeResponseToJSON(json: any): HandlerSingleChallengeResponse {
      return HandlerSingleChallengeResponseToJSONTyped(json, false);
  }

  export function HandlerSingleChallengeResponseToJSONTyped(value?: HandlerSingleChallengeResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'categorySlug': value['categorySlug'],
        'description': value['description'],
        'difficulty': ChallengeDifficultyToJSON(value['difficulty']),
        'name': value['name'],
        'order': value['order'],
        'slug': value['slug'],
        'staticFilesArchiveUrl': value['staticFilesArchiveUrl'],
    };
}

