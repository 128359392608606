/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HandlerChallengeIdentifier
 */
export interface HandlerChallengeIdentifier {
    /**
     * The slug of the challenge's category.
     * @type {string}
     * @memberof HandlerChallengeIdentifier
     */
    categorySlug: string;
    /**
     * The slug of the challenge.
     * @type {string}
     * @memberof HandlerChallengeIdentifier
     */
    challengeSlug: string;
}

/**
 * Check if a given object implements the HandlerChallengeIdentifier interface.
 */
export function instanceOfHandlerChallengeIdentifier(value: object): value is HandlerChallengeIdentifier {
    if (!('categorySlug' in value) || value['categorySlug'] === undefined) return false;
    if (!('challengeSlug' in value) || value['challengeSlug'] === undefined) return false;
    return true;
}

export function HandlerChallengeIdentifierFromJSON(json: any): HandlerChallengeIdentifier {
    return HandlerChallengeIdentifierFromJSONTyped(json, false);
}

export function HandlerChallengeIdentifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerChallengeIdentifier {
    if (json == null) {
        return json;
    }
    return {
        
        'categorySlug': json['categorySlug'],
        'challengeSlug': json['challengeSlug'],
    };
}

  export function HandlerChallengeIdentifierToJSON(json: any): HandlerChallengeIdentifier {
      return HandlerChallengeIdentifierToJSONTyped(json, false);
  }

  export function HandlerChallengeIdentifierToJSONTyped(value?: HandlerChallengeIdentifier | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'categorySlug': value['categorySlug'],
        'challengeSlug': value['challengeSlug'],
    };
}

