import { ErrorEvent } from '@sentry/types'
import * as Sentry from '@sentry/vue'
import { App } from 'vue'

export function initSentry(app: App): void {
  const dsn = import.meta.env.VITE_SENTRY_DSN || window.SENTRY_DSN
  if (!dsn) {
    // eslint-disable-next-line no-console
    console.log('Not enabling Sentry as no DSN is set.')
    return
  }
  const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT || window.SENTRY_ENVIRONMENT || 'unknown'
  const release = import.meta.env.VITE_SENTRY_RELEASE || window.SENTRY_RELEASE || 'unknown'
  Sentry.init({
    app,
    dsn,
    environment,
    release,
    logErrors: true,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded', // https://stackoverflow.com/a/50387233/8155769
      'ResizeObserver loop completed with undelivered notifications.', // Same as above, but Firefox
    ],
    beforeSend(event: ErrorEvent): PromiseLike<ErrorEvent | null> | ErrorEvent | null {
      if (event.exception && event.event_id) {
        Sentry.showReportDialog({ eventId: event.event_id })
      }
      return event
    },
  })
}
