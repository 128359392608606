/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HandlerFlagSolveDto
 */
export interface HandlerFlagSolveDto {
    /**
     * The timestamps when this flag was successfully submitted.
     * @type {Array<string>}
     * @memberof HandlerFlagSolveDto
     */
    solveTimes: Array<string>;
}

/**
 * Check if a given object implements the HandlerFlagSolveDto interface.
 */
export function instanceOfHandlerFlagSolveDto(value: object): value is HandlerFlagSolveDto {
    if (!('solveTimes' in value) || value['solveTimes'] === undefined) return false;
    return true;
}

export function HandlerFlagSolveDtoFromJSON(json: any): HandlerFlagSolveDto {
    return HandlerFlagSolveDtoFromJSONTyped(json, false);
}

export function HandlerFlagSolveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerFlagSolveDto {
    if (json == null) {
        return json;
    }
    return {
        
        'solveTimes': json['solveTimes'],
    };
}

  export function HandlerFlagSolveDtoToJSON(json: any): HandlerFlagSolveDto {
      return HandlerFlagSolveDtoToJSONTyped(json, false);
  }

  export function HandlerFlagSolveDtoToJSONTyped(value?: HandlerFlagSolveDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'solveTimes': value['solveTimes'],
    };
}

