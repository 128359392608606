/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlerExposedEndpointDto } from './HandlerExposedEndpointDto';
import {
    HandlerExposedEndpointDtoFromJSON,
    HandlerExposedEndpointDtoFromJSONTyped,
    HandlerExposedEndpointDtoToJSON,
    HandlerExposedEndpointDtoToJSONTyped,
} from './HandlerExposedEndpointDto';
import type { OrchestratorChallengeState } from './OrchestratorChallengeState';
import {
    OrchestratorChallengeStateFromJSON,
    OrchestratorChallengeStateFromJSONTyped,
    OrchestratorChallengeStateToJSON,
    OrchestratorChallengeStateToJSONTyped,
} from './OrchestratorChallengeState';

/**
 * 
 * @export
 * @interface HandlerChallengeInstanceDto
 */
export interface HandlerChallengeInstanceDto {
    /**
     * Endpoints that are exposed to the participant.
     * @type {Array<HandlerExposedEndpointDto>}
     * @memberof HandlerChallengeInstanceDto
     */
    exposedEndpoints: Array<HandlerExposedEndpointDto>;
    /**
     * The timestamp when the challenge instance will be stopped without prior prolonging.
     * @type {string}
     * @memberof HandlerChallengeInstanceDto
     */
    scheduledStopTime: string;
    /**
     * The state of the challenge. Is it stopped, starting, etc.?
     * @type {OrchestratorChallengeState}
     * @memberof HandlerChallengeInstanceDto
     */
    state: OrchestratorChallengeState;
}



/**
 * Check if a given object implements the HandlerChallengeInstanceDto interface.
 */
export function instanceOfHandlerChallengeInstanceDto(value: object): value is HandlerChallengeInstanceDto {
    if (!('exposedEndpoints' in value) || value['exposedEndpoints'] === undefined) return false;
    if (!('scheduledStopTime' in value) || value['scheduledStopTime'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    return true;
}

export function HandlerChallengeInstanceDtoFromJSON(json: any): HandlerChallengeInstanceDto {
    return HandlerChallengeInstanceDtoFromJSONTyped(json, false);
}

export function HandlerChallengeInstanceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerChallengeInstanceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'exposedEndpoints': ((json['exposedEndpoints'] as Array<any>).map(HandlerExposedEndpointDtoFromJSON)),
        'scheduledStopTime': json['scheduledStopTime'],
        'state': OrchestratorChallengeStateFromJSON(json['state']),
    };
}

  export function HandlerChallengeInstanceDtoToJSON(json: any): HandlerChallengeInstanceDto {
      return HandlerChallengeInstanceDtoToJSONTyped(json, false);
  }

  export function HandlerChallengeInstanceDtoToJSONTyped(value?: HandlerChallengeInstanceDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'exposedEndpoints': ((value['exposedEndpoints'] as Array<any>).map(HandlerExposedEndpointDtoToJSON)),
        'scheduledStopTime': value['scheduledStopTime'],
        'state': OrchestratorChallengeStateToJSON(value['state']),
    };
}

