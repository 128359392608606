/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlerErrorCode } from './HandlerErrorCode';
import {
    HandlerErrorCodeFromJSON,
    HandlerErrorCodeFromJSONTyped,
    HandlerErrorCodeToJSON,
    HandlerErrorCodeToJSONTyped,
} from './HandlerErrorCode';

/**
 * 
 * @export
 * @interface HandlerErrorResponse
 */
export interface HandlerErrorResponse {
    /**
     * An error code.
     * @type {HandlerErrorCode}
     * @memberof HandlerErrorResponse
     */
    code: HandlerErrorCode;
    /**
     * A descriptive message.
     * @type {string}
     * @memberof HandlerErrorResponse
     */
    message: string;
    /**
     * Whether the request was successful.
     * @type {boolean}
     * @memberof HandlerErrorResponse
     */
    ok: boolean;
}



/**
 * Check if a given object implements the HandlerErrorResponse interface.
 */
export function instanceOfHandlerErrorResponse(value: object): value is HandlerErrorResponse {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('ok' in value) || value['ok'] === undefined) return false;
    return true;
}

export function HandlerErrorResponseFromJSON(json: any): HandlerErrorResponse {
    return HandlerErrorResponseFromJSONTyped(json, false);
}

export function HandlerErrorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerErrorResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'code': HandlerErrorCodeFromJSON(json['code']),
        'message': json['message'],
        'ok': json['ok'],
    };
}

  export function HandlerErrorResponseToJSON(json: any): HandlerErrorResponse {
      return HandlerErrorResponseToJSONTyped(json, false);
  }

  export function HandlerErrorResponseToJSONTyped(value?: HandlerErrorResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'code': HandlerErrorCodeToJSON(value['code']),
        'message': value['message'],
        'ok': value['ok'],
    };
}

