import { ref } from 'vue'

import {
  Configuration,
  ErrorContext,
  Middleware,
  DefaultApi,
  ChallengesApi,
  CategoriesApi,
  UsersApi,
  FetchParams,
  RequestContext,
  ScoreApi,
} from '@/api'

const retryPeriod = 2 // in seconds
const requestTimeout = 15 // in seconds

export const networkErrorsPresent = ref(0)

const isNetworkErrorException = (exc: unknown): boolean => {
  return exc instanceof TypeError && (
    exc.message.includes('NetworkError') || // Firefox
      exc.message.includes('Failed to fetch') || // Chrome
      exc.message.includes('Load failed') // Safari
  )
}

const isTimeout = (exc: unknown): boolean => {
  // See https://developer.mozilla.org/en-US/docs/Web/API/AbortController
  return (exc instanceof DOMException && exc.name === 'AbortError')
}

const getTimeout = (): AbortSignal => {
  const abortController = new AbortController()
  setTimeout(() => {
    abortController.abort()
  }, requestTimeout * 1000)
  return abortController.signal
}

export class APIMiddleware implements Middleware {
  pre(context: RequestContext): Promise<void | FetchParams> {
    // Apply a global timeout for non-responding APIs.
    return Promise.resolve({
      ...context,
      init: {
        ...context.init,
        signal: getTimeout(),
      },
    })
  }

  async onError?(context: ErrorContext): Promise<Response | void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    if (isNetworkErrorException(context.error) || isTimeout(context.error)) {
      networkErrorsPresent.value++

      // Retry request if we encounter a network error
      while (true) {
        await new Promise(resolve => setTimeout(resolve, retryPeriod * 1000))
        try {
          const result = await fetch(
            context.url,
            {
              ...context.init,
              // Need to create a new timeout for the retry.
              signal: getTimeout(),
            },
          )
          networkErrorsPresent.value--
          return result
        } catch (exc) {
          // TODO: log if timeout.
          // ensure to throw exception if we get another Exception
          if (!isNetworkErrorException(exc) && !isTimeout(exc)) {
            throw exc
          }
        }
      }
    }

    return context.response
  }
}

export type Api = {
  categories: CategoriesApi,
  challenges: ChallengesApi,
  default: DefaultApi,
  score: ScoreApi,
  users: UsersApi,
}

function getBasePath(): string {
  const basePath = import.meta.env.VITE_API_BASE_URL || window.API_BASE_URL
  if (!basePath) {
    throw new Error('Please set VITE_API_BASE_URL.')
  }
  return basePath
}

export function produceAPI(accessToken: string): Api {
  const basePath = getBasePath()
  const config = new Configuration({
    basePath,
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    middleware: [
      new APIMiddleware(),
    ],
  })
  return {
    categories: new CategoriesApi(config),
    challenges: new ChallengesApi(config),
    default: new DefaultApi(config),
    score: new ScoreApi(config),
    users: new UsersApi(config),
  }
}
