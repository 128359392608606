import katex from 'katex'
import MarkdownIt from 'markdown-it'
import { type StateBlock, type StateInline } from 'markdown-it'

export const KatexPlugin = (md: MarkdownIt): void => {
  // Catch all math blocks. Make sure to be checked before fences,
  // as we have our own custom fence.
  md.block.ruler.before('fence', 'math_block', mathBlockRuler)
  // And catch math inline (inline stuff is being processed after blocks).
  md.inline.ruler.before('backticks', 'math_inline', mathInlineRuler)

  md.renderer.rules.math_block = (tokens, idx) => renderMath(tokens[idx].content, true)
  md.renderer.rules.math_inline = (tokens, idx) => renderMath(tokens[idx].content, false)
}

function renderMath(
  content: string,
  isBlock: boolean,
): string {
  try {
    return katex.renderToString(content, {
      displayMode: isBlock,
    })
  } catch (ex) {
    if (ex instanceof Error) {
      return `<span style="color: red;">Error rendering maths: ${ex.message}</span>`
    }
    return '<span style="color: red;">Error rendering maths</span>'
  }
}

function mathInlineRuler(state: StateInline, silent: boolean): boolean {
  if (state.src[state.pos] !== '$') {
    return false
  }

  const startDelimiter = checkInlineOpeningDelimiter(state, state.pos)
  if (!startDelimiter.canOpen) {
    if (!silent) {
      // Pending text is flushed as text node.
      state.pending += '$'
    }
    state.pos += 1
    return true
  }

  let startDelimiterText: string
  let endDelimiterText: string
  let delimiterLength: number

  switch (startDelimiter.type) {
    case 'singleDollar':
      startDelimiterText = endDelimiterText = '$'
      delimiterLength = 1
      break
    case 'dollarBacktick':
      startDelimiterText = '$`'
      endDelimiterText = '`$'
      delimiterLength = 2
      break
  }

  const inlineMathStart = state.pos + delimiterLength

  // Find end of inline block while skipping properly escaped delimiters.
  const closingDelimiterPosition = findClosingDelimiter(state, inlineMathStart, startDelimiter.type)

  // We did not find a closing delimiter, ignore the starting delimiter.
  if (closingDelimiterPosition === -1) {
    if (!silent) {
      state.pending += startDelimiterText
    }
    state.pos += delimiterLength
    return true
  }

  // Also ignore empty content by also skipping the end delimiter.
  if (closingDelimiterPosition - inlineMathStart === 0) {
    if (!silent) {
      state.pending += `${startDelimiterText}${endDelimiterText}`
    }
    state.pos += delimiterLength * 2
    return true
  }

  // Add a new token which will be rendered later.
  if (!silent) {
    const token = state.push('math_inline', 'math', 0)
    token.markup = startDelimiterText
    token.content = state.src.slice(inlineMathStart, closingDelimiterPosition)
  }

  // Continue after the math block.
  state.pos = closingDelimiterPosition + delimiterLength
  return true
}

type InlineDelimiterType = 'dollarBacktick' | 'singleDollar'

// A Unicode whitespace character is a character in the Unicode Zs general category, or a tab (U+0009), line feed (U+000A),
// form feed (U+000C), or carriage return (U+000D).
// See https://spec.commonmark.org/0.31.2/#left-flanking-delimiter-run.
// "For purposes of this definition, the beginning and the end of the line count as Unicode whitespace."
const isWhitespaceRegex = /^[\p{Zs}\t\n\f\r]$/u
const isUnicodePunctuationCharRegex = /^[\p{P}\p{S}]$/u

/**
 * Checks whether the string at the given position contains a left flanking delimiter run.
 * See https://spec.commonmark.org/0.31.2/#left-flanking-delimiter-run.
 */
function isLeftFlankingDelimiterRun(str: string, position: number) {
  const nextChar = position >= str.length ? ' ' : str.charAt(position + 1)
  const previousChar = position > 0 ? str.charAt(position - 1) : ' '

  const isFollowedByWhitespace = isWhitespaceRegex.test(nextChar)
  const isFollowedByPunctuation = isUnicodePunctuationCharRegex.test(nextChar)
  const isPreceededByWhitespace = isWhitespaceRegex.test(previousChar)
  const isPreceededByPunctuation = isUnicodePunctuationCharRegex.test(previousChar)
  // (1) not followed by Unicode whitespace
  // and 2(a) not followed by a Unicode punctuation character
  // or 2(b) followed by a Unicode punctuation character and preceded by Unicode whitespace or a Unicode punctuation character.
  return !isFollowedByWhitespace &&
    ((!isFollowedByPunctuation) || (isFollowedByPunctuation && (isPreceededByPunctuation || isPreceededByWhitespace)))
}

/**
 * Checks whether the string at the given position contains a right flanking delimiter run.
 * See https://spec.commonmark.org/0.31.2/#right-flanking-delimiter-run.
 */
function isRightFlankingDelimiterRun(str: string, position: number) {
  const nextChar = position >= str.length ? ' ' : str.charAt(position + 1)
  const previousChar = position > 0 ? str.charAt(position - 1) : ' '

  const isFollowedByWhitespace = isWhitespaceRegex.test(nextChar)
  const isFollowedByPunctuation = isUnicodePunctuationCharRegex.test(nextChar)
  const isPreceededByWhitespace = isWhitespaceRegex.test(previousChar)
  const isPreceededByPunctuation = isUnicodePunctuationCharRegex.test(previousChar)
  // (1) not preceded by Unicode whitespace
  // and 2(a) not preceded by a Unicode punctuation character
  // or 2(b) preceded by a Unicode punctuation character and followed by Unicode whitespace or a Unicode punctuation character.
  return !isPreceededByWhitespace &&
    ((!isPreceededByPunctuation) || (isPreceededByPunctuation && (isFollowedByWhitespace || isFollowedByPunctuation)))
}

function checkInlineOpeningDelimiter(
  state: StateInline,
  position: number,
): { canOpen: false } | { canOpen: true, type: InlineDelimiterType } {
  const maxPosition = state.posMax

  const nextChar = position + 1 <= maxPosition ? state.src.charAt(position + 1) : -1
  if (!isLeftFlankingDelimiterRun(state.src, position)) {
    return {
      canOpen: false,
    }
  }
  switch (nextChar) {
    case '`':
      return {
        canOpen: true,
        type: 'dollarBacktick',
      }
    default:
      return {
        canOpen: true,
        type: 'singleDollar',
      }
  }
}

function findClosingDelimiter(
  state: StateInline,
  searchStartPosition: number,
  openingDelimiterType: InlineDelimiterType,
): number {
  let match: number = searchStartPosition

  let searchString: string
  switch (openingDelimiterType) {
    case 'singleDollar':
      searchString = '$'
      break
    case 'dollarBacktick':
      searchString = '`$'
      break
  }

  while ((match = state.src.indexOf(searchString, match)) !== -1) {
    // Check for escape characters.
    let position = match - 1
    while (state.src[position] === '\\') {
      position -= 1
    }

    // If the number of escapes is even, then we found a valid end delimiter,
    // as double escapes do no escape.
    if (((match - position) % 2) === 1 && isRightFlankingDelimiterRun(state.src, match)) {
      break
    }
    match += 1
  }

  if (match === -1) {
    return -1
  }

  return match
}

type BlockDelimiter = {
  start: string,
  end: string,
}
const blockDelimiters: BlockDelimiter[] = [
  {
    start: '$$',
    end: '$$',
  },
  {
    start: '```math',
    end: '```',
  },
]

function mathBlockRuler(state: StateBlock, startLine: number, endLine: number, silent: boolean): boolean {
  for (const delimiter of blockDelimiters) {
    let position = state.bMarks[startLine] + state.tShift[startLine]
    let maxPosition = state.eMarks[startLine]
    let lastPos
    let found = false

    // We at least need enough characters for the start delimiter.
    if (position + delimiter.start.length > maxPosition) {
      continue
    }
    // Check for the start delimiter.
    if (state.src.slice(position, position + delimiter.start.length) !== delimiter.start) {
      continue
    }

    position += delimiter.start.length
    let firstLine = state.src.slice(position, maxPosition)
    let lastLine: string | null = null

    if (silent) {
      return true
    }

    // Check for the end delimiter ending the line.
    // This would be a single line expression.
    if (firstLine.trim().slice(-delimiter.end.length) === delimiter.end) {
      firstLine = firstLine.trim().slice(0, -delimiter.end.length)
      found = true
    }

    // Search for the end delimiter in the following lines.
    let line: number = startLine
    while (!found) {
      line++

      if (line >= endLine) {
        break
      }

      position = state.bMarks[line] + state.tShift[line]
      maxPosition = state.eMarks[line]

      if (state.src.slice(position, maxPosition).trim().slice(-delimiter.end.length) === delimiter.end) {
        lastPos = state.src.slice(0, maxPosition).lastIndexOf(delimiter.end)
        lastLine = state.src.slice(position, lastPos)
        found = true
      }
    }

    if (!found) {
      // No end delimiter found, also ignore opening delimiter.
      continue
    }

    const lineAfterBlockEnd = line + 1
    const token = state.push('math_block', 'math', 0)
    token.block = true
    token.content = (firstLine && firstLine.trim() ? firstLine + '\n' : '') +
      state.getLines(startLine + 1, line, state.tShift[startLine], true) +
      (lastLine && lastLine.trim() ? lastLine : '')
    token.map = [startLine, lineAfterBlockEnd]
    token.markup = delimiter.start

    // Go to the line after the end of the block
    state.line = lineAfterBlockEnd

    return true
  }

  return false
}
