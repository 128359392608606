/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HandlerScoreGroupDto
 */
export interface HandlerScoreGroupDto {
    /**
     * A description of the score group. Can be markdown.
     * @type {string}
     * @memberof HandlerScoreGroupDto
     */
    description: string;
    /**
     * The display name of the score group.
     * @type {string}
     * @memberof HandlerScoreGroupDto
     */
    name: string;
    /**
     * The identifier of the score group.
     * @type {string}
     * @memberof HandlerScoreGroupDto
     */
    slug: string;
}

/**
 * Check if a given object implements the HandlerScoreGroupDto interface.
 */
export function instanceOfHandlerScoreGroupDto(value: object): value is HandlerScoreGroupDto {
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('slug' in value) || value['slug'] === undefined) return false;
    return true;
}

export function HandlerScoreGroupDtoFromJSON(json: any): HandlerScoreGroupDto {
    return HandlerScoreGroupDtoFromJSONTyped(json, false);
}

export function HandlerScoreGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerScoreGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'],
        'name': json['name'],
        'slug': json['slug'],
    };
}

  export function HandlerScoreGroupDtoToJSON(json: any): HandlerScoreGroupDto {
      return HandlerScoreGroupDtoToJSONTyped(json, false);
  }

  export function HandlerScoreGroupDtoToJSONTyped(value?: HandlerScoreGroupDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'description': value['description'],
        'name': value['name'],
        'slug': value['slug'],
    };
}

